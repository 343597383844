@media (min-width: 993px) {
  .main {
  }
}

.main {
  h2.rank-up {
    position: relative;
    display: inline-block;
    padding: 0 25%;
    box-sizing:border-box;
    margin:0;
  }
  
  h2.rank-up:before, h2.rank-up:after {
    content: '';
    position: absolute;
    top: 50%;
    display: inline-block;
    width: 25%;
    height: 1px;
    background-color: #B2C8CE;
  }
  
  h2.rank-up:before {
    left:0;
  }
  h2.rank-up:after {
    right: 0;
  }
  h3 {
    color: #00495b;
    font-weight: bold;
    display: inline-block;
    font-size: 20px;
    border: 1px solid #00495b;
    padding: 13px 20px 10px 20px;
    background-color: #e5edef;
    font-size: 25px;
    font-weight: 600;
  }

  h3.fin{
    background-color: transparent;
    border: none;
    display: inline-block;
    padding: 0;
    font-size:30px;
    margin-left:20px;
  }

  h3.fin2{
    background-color: transparent;
    border: none;
    display: inline-block;
    padding: 0;
    font-size:30px;
  }

  h3.name-box{
    font-size:18px;
    border:none;
    border-bottom:1px solid #B2C8CE;
    display:block;
    background-color: transparent;
    padding:0;
    padding-bottom:10px;
    color:#000000;
    font-weight:normal;
    span{
      font-size: 14px;
      margin-left:5px;
    }
  }

  h4.card-status{
    color:#00495b;
    font-size:18px;
    padding:15px 0 20px;
    border-bottom:1px solid #B2C8CE;
    margin:0;
  }

  .btn-submit{
    text-align:center;
    button{
      width:200px;
      font-size:20px;
      font-weight:bold;
    }
  }

  .btn-col2 button{
    margin:0 10px;
  }

  .row{
    margin:0;
  }

  .attention{
    color:red;
    display:inline-block;
    text-decoration: underline;
    margin-bottom:15px;
  }

  .attention:hover{
    color:red;
  }

  .card-img{
    text-align:center;
    padding:0;
    img{
      width:60%;
      margin:0 auto;
    }
  }

  .mem-info{
    padding:0;
    .rankup-info{
      color:#00495b;
    }
    .info-list{
      display: flex;
      flex-wrap: wrap;
      dt{
        padding:15px;
        border-bottom:1px solid #B2C8CE;
        border-right:1px solid #B2C8CE;
        margin:0;
        width:45%;
        font-weight:normal;
      }
      dd{
        padding:15px;
        border-bottom:1px solid #B2C8CE;
        margin:0;
        width:55%;
        .point-link{
          display:inline-block;
          font-size:12px;
          background-color:#00495b;
          color:#FFFFFF;
          padding:6px 10px 4px;
          line-height:12px;
          border-radius: 3px;
          float:right;
        }
        .point-link::before{
          transform: translateY(-2px);
          content:"　";
          display:inline-block;
          vertical-align: middle;
          margin-right:5px;
          width:7px;
          height:12px;
          background-image: url("../images/arrow03.png");
          background-size:cover;
        }
      }
    }
  } 

  .rankup-alert{
    display:none;
    margin:0 auto;
    padding:20px;
    border:1px solid #FF7B83;
    margin-bottom:20px;
    position:relative;
    color:#FF7B83;
  }

  .rankup-alert.active{
    display:block;
  }

  .rankup-alert::before{
    content:"　";
    display:block;
    width:30px;
    height:30px;
    background-image: url("../images/attention.png");
    background-size:cover;
    position:absolute;
    top:0;
    left:0;
    transform: translate(-50%,-50%);
  }

  .modal-content{
    border:none;
    border-radius: 0px;
    padding:20px;
    margin:3%;
    width:94%;
    box-sizing:border-box;
    .modal-header,.modal-footer{
      border-color: #B2C8CE;
    }
    .modal-body{
      display:flex;
      align-items:center;
      p{
        text-align:center;
        margin:0;
        color:#00495b;
        img{
          width:100%;
        }
      }
      .card-parts01{
        width:40%;
      }
      .card-parts02{
        width:20%;
        img{
          width:20%;
          margin:0 auto;
          transform: translateY(-12px);
        }
      }
      .card-parts03{
        width:40%;
        font-weight:bold;
      }
    }
    .modal-footer{
      display:block;
      padding:20px 0 0;
      .align-center{
        text-align:center;
        .btn{
          background-color:#00495b;
          padding:2px 50px;
          border-radius:0;
          font-size:12px;
        }
      }
    }
  }
  .modal-dialog{
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  margin: auto;
  pointer-events: none;
  .modal-content{
    pointer-events: all;
  }
}

  p.step-sub {
    color: #00495b;
    font-weight: bold;
    margin-top: 10px;
    span{
      text-decoration: underline;
    }
  }

  .flow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    z-index:-100;
    li {
      border: 1px solid #00495b;
      padding: 15px;
      color: #00495b;
      text-align: center;
      position: relative;
      width: 22%;
      box-sizing: border-box;
      display: flex;
      align-items: top;
      flex-wrap: wrap;
      justify-content: center;
      p {
        text-align: center;
        height: 30px;
        margin-bottom: 10px;
        font-size: 25px;
        font-weight: 600;
      }
      span {
        text-align: center;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: bold;
        height:60px;
      }
    }
    li::after {
      content: "　";
      width: 13px;
      height: 26px;
      background-image: url("../images/arrow01.png");
      display: block;
      position: absolute;
      top: 50%;
      bottom: 0;
      left: 107%;
      transform: translate(0, -50%);
    }
    li:last-child::after {
      content: none;
    }
    li.active {
      background-color: #e5edef;
    }

  }

  .border-box {
    border: 1px solid #b3b3b3;
    box-sizing: border-box;
    padding: 30px;
    
  }
  .border-box.login-box{
    padding:50px;
  }

  form {
    dl {
      dt {
        font-weight: normal;
        margin:20px 0 5px 0;
        line-height:24px;
        display:inline-block;
        vertical-align: middle;
      }
      dt:first-child{
        margin:0 0 5px 0;
      }
      dt.required::after{
        content:"必須";
        display:inline-block;
        color:#ffffff;
        font-size:12px;
        padding:3px 5px;
        margin-left:10px;
        background-color:#ff7b83;
        vertical-align: top;
        line-height:16px;
      }
      dt.magazine{
        font-weight:bold;
        margin-bottom:20px;
      }
      dt.magazine::after{
        font-weight:normal;
      }
      dd{
        input,select{
          margin-top:5px;
          border-radius:0;
          border:1px solid #b3b3b3;
        }
        input::placeholder {
          opacity:0.7;
        }

        input.active{
          border:1px solid #ff7b83;
        }
        input.active2{
          border:1px solid #ff7b83;
        }
        .input-inline{
          max-width:300px;
          width:49%;
          display:inline-block;
          margin-left:2%;
        }
        .input-inline:first-child{
          margin-left:0px;
        }
        .input-inline.adress-number{
          max-width:100px;
          margin-left:0;
        }
        div.input-inline{
          vertical-align: top;
        }
        span.input-bar{
          display:inline-block;
          padding:0 5px;
          font-size:20px;
        }
        .input-inline p.input-title{
          margin-bottom:0;
          margin-top:0px;
        }
        label{
          margin:0 10px 0 0;
          input{
            margin-right:5px;
          }
          
        }

      }
      .error-text{
        font-size:14px;
        color:#ff7b83;
        margin:10px 0 0 0;
        display: none;
      }
      .error-text.active{
        display: block;
      }
      .error-text.active2{
        display: block;
      }
    }

  }
  .error-box{
   display:none;
  }
  .error-box.active-box{
    display:block!important;
    border:1px solid #ff7b83;
    padding:0 10px 10px 10px;
    .error-text{
      font-size:14px;
      color:#ff7b83;
      margin:10px 0 0 0;
      display:none;
    }
    .error-text.active{
      display: block;
    }
    .error-text.active2{
      display: block;
    }
  }

  .btn{
    background-color:#b3b3b3;
    color:#FFFFFF;
  }
}

.information{
  h3{
  color: #bfa45a;
  border: 1px solid #bfa45a;
  background-color: #F9F6EE;
  }
  p.step-sub {
    color: #bfa45a;
  }
  .flow {
    justify-content: center;
  li {
    border: 1px solid #bfa45a;
    color: #bfa45a;
    margin-left:4%;
  }
  li.active {
    background-color: #F9F6EE;
  }
  li:first-child {
    margin-left:0;
  }
  li::after {
    background-image: url("../images/arrow02.png");
  }
  }

}



@media (max-width: 750px) {
  .main {


    .card-img{
      img{
      width:50%;
    }
    }
    h3.name-box{
      border-color:#00495b;
    }

    .mem-info{
      .card-status{
        text-align: center;
        padding-top:0;
        border-color:#00495b;
        margin-bottom:30px;
      }

      .info-list{
        display:block;
        text-align: center;
        dt{
          font-weight:bold;
          width:100%;
          border-right:none;
          padding:5px 0;
        }
        dd{
          width:100%;
          border-bottom:none;
          padding:8px 0;
          margin-bottom:30px;
          font-size:18px;
          .point-link{
            float:none;
            display:block;
            background-color:transparent;
            color:#00495b;
            font-size:14px;
            margin-top:15px;
            font-weight:bold;
          }
          .point-link::before{
            content:none;
          }
          .point-link::after{
            transform: translateY(-2px);
            content:"　";
            display:inline-block;
            vertical-align: middle;
            margin-left:8px;
            width:9px;
            height:16px;
            background-image: url("../images/arrow04.png");
            background-size:cover;
          }
        }
      }
      .rankup-alert{
        margin:0 auto;
        padding:20px;
        width:95%;
        border:1px solid #FF7B83;
        margin-bottom:20px;
        position:relative;
        color:#FF7B83;
      }
    }


  form{
    dl{
    dd{
      .input-inline{
        margin-left:0;
        margin-top:10px;
        display:block;
        width:100%;
        max-width: 100%;
      }
      .input-inline:first-child{
        margin-top:0px;
      }
      .input-inline.adress-number{
        display:inline-block;
        margin-left:0;
        margin-top:0px;
        max-width:20%;
      }
    }
  }
  }
  .btn-submit{
    text-align:center;
    button{
      width:50%;
      font-size:20px;
      font-weight:bold;
    }
  }

  .btn-col2 button:first-child{
    margin: 0 0 10px 0 ;
  }
}
}


@media (max-width: 992px) {
  .main {
    h3 {
      padding: 10px;
      font-size: 18px;
      display: block;
      background-color: transparent;
      span {
        margin-left: 10px;
      }
    }

    .flow {
      margin-bottom: 20px;
      li {
        padding: 10px 15px;
        p {
          height: auto;
          font-size: 18px;
          margin-bottom:0;
        }
      }
      li::after {
        display: none;
      }
      li.active::after {
        content: "　";
        width: 8px;
        height: 16px;
        background-size: contain;
        background-repeat: no-repeat;
        display: block;
        position: absolute;
        top: 124%;
        bottom: 0;
        left: auto;
        transform: translate(0, -50%) rotate(90deg);
      }
      li:last-child::after {
        content: "　";
      }
    }
    .border-box {
      padding: 15px;
    }

  }
}


