@charset "UTF-8";
.main h2.rank-up {
  position: relative;
  display: inline-block;
  padding: 0 25%;
  box-sizing: border-box;
  margin: 0;
}

.main h2.rank-up:before, .main h2.rank-up:after {
  content: '';
  position: absolute;
  top: 50%;
  display: inline-block;
  width: 25%;
  height: 1px;
  background-color: #B2C8CE;
}

.main h2.rank-up:before {
  left: 0;
}

.main h2.rank-up:after {
  right: 0;
}

.main h3 {
  color: #00495b;
  font-weight: bold;
  display: inline-block;
  font-size: 20px;
  border: 1px solid #00495b;
  padding: 13px 20px 10px 20px;
  background-color: #e5edef;
  font-size: 25px;
  font-weight: 600;
}

.main h3.fin {
  background-color: transparent;
  border: none;
  display: inline-block;
  padding: 0;
  font-size: 30px;
  margin-left: 20px;
}

.main h3.fin2 {
  background-color: transparent;
  border: none;
  display: inline-block;
  padding: 0;
  font-size: 30px;
}

.main h3.name-box {
  font-size: 18px;
  border: none;
  border-bottom: 1px solid #B2C8CE;
  display: block;
  background-color: transparent;
  padding: 0;
  padding-bottom: 10px;
  color: #000000;
  font-weight: normal;
}

.main h3.name-box span {
  font-size: 14px;
  margin-left: 5px;
}

.main h4.card-status {
  color: #00495b;
  font-size: 18px;
  padding: 15px 0 20px;
  border-bottom: 1px solid #B2C8CE;
  margin: 0;
}

.main .btn-submit {
  text-align: center;
}

.main .btn-submit button {
  width: 200px;
  font-size: 20px;
  font-weight: bold;
}

.main .btn-col2 button {
  margin: 0 10px;
}

.main .row {
  margin: 0;
}

.main .attention {
  color: red;
  display: inline-block;
  text-decoration: underline;
  margin-bottom: 15px;
}

.main .attention:hover {
  color: red;
}

.main .card-img {
  text-align: center;
  padding: 0;
}

.main .card-img img {
  width: 60%;
  margin: 0 auto;
}

.main .mem-info {
  padding: 0;
}

.main .mem-info .rankup-info {
  color: #00495b;
}

.main .mem-info .info-list {
  display: flex;
  flex-wrap: wrap;
}

.main .mem-info .info-list dt {
  padding: 15px;
  border-bottom: 1px solid #B2C8CE;
  border-right: 1px solid #B2C8CE;
  margin: 0;
  width: 45%;
  font-weight: normal;
}

.main .mem-info .info-list dd {
  padding: 15px;
  border-bottom: 1px solid #B2C8CE;
  margin: 0;
  width: 55%;
}

.main .mem-info .info-list dd .point-link {
  display: inline-block;
  font-size: 12px;
  background-color: #00495b;
  color: #FFFFFF;
  padding: 6px 10px 4px;
  line-height: 12px;
  border-radius: 3px;
  float: right;
}

.main .mem-info .info-list dd .point-link::before {
  transform: translateY(-2px);
  content: "　";
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  width: 7px;
  height: 12px;
  background-image: url("../images/arrow03.png");
  background-size: cover;
}

.main .rankup-alert {
  display: none;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #FF7B83;
  margin-bottom: 20px;
  position: relative;
  color: #FF7B83;
}

.main .rankup-alert.active {
  display: block;
}

.main .rankup-alert::before {
  content: "　";
  display: block;
  width: 30px;
  height: 30px;
  background-image: url("../images/attention.png");
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
}

.main .modal-content {
  border: none;
  border-radius: 0px;
  padding: 20px;
  margin: 3%;
  width: 94%;
  box-sizing: border-box;
}

.main .modal-content .modal-header, .main .modal-content .modal-footer {
  border-color: #B2C8CE;
}

.main .modal-content .modal-body {
  display: flex;
  align-items: center;
}

.main .modal-content .modal-body p {
  text-align: center;
  margin: 0;
  color: #00495b;
}

.main .modal-content .modal-body p img {
  width: 100%;
}

.main .modal-content .modal-body .card-parts01 {
  width: 40%;
}

.main .modal-content .modal-body .card-parts02 {
  width: 20%;
}

.main .modal-content .modal-body .card-parts02 img {
  width: 20%;
  margin: 0 auto;
  transform: translateY(-12px);
}

.main .modal-content .modal-body .card-parts03 {
  width: 40%;
  font-weight: bold;
}

.main .modal-content .modal-footer {
  display: block;
  padding: 20px 0 0;
}

.main .modal-content .modal-footer .align-center {
  text-align: center;
}

.main .modal-content .modal-footer .align-center .btn {
  background-color: #00495b;
  padding: 2px 50px;
  border-radius: 0;
  font-size: 12px;
}

.main .modal-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  margin: auto;
  pointer-events: none;
}

.main .modal-dialog .modal-content {
  pointer-events: all;
}

.main p.step-sub {
  color: #00495b;
  font-weight: bold;
  margin-top: 10px;
}

.main p.step-sub span {
  text-decoration: underline;
}

.main .flow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  z-index: -100;
}

.main .flow li {
  border: 1px solid #00495b;
  padding: 15px;
  color: #00495b;
  text-align: center;
  position: relative;
  width: 22%;
  box-sizing: border-box;
  display: flex;
  align-items: top;
  flex-wrap: wrap;
  justify-content: center;
}

.main .flow li p {
  text-align: center;
  height: 30px;
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: 600;
}

.main .flow li span {
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  height: 60px;
}

.main .flow li::after {
  content: "　";
  width: 13px;
  height: 26px;
  background-image: url("../images/arrow01.png");
  display: block;
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 107%;
  transform: translate(0, -50%);
}

.main .flow li:last-child::after {
  content: none;
}

.main .flow li.active {
  background-color: #e5edef;
}

.main .border-box {
  border: 1px solid #b3b3b3;
  box-sizing: border-box;
  padding: 30px;
}

.main .border-box.login-box {
  padding: 50px;
}

.main form dl dt {
  font-weight: normal;
  margin: 20px 0 5px 0;
  line-height: 24px;
  display: inline-block;
  vertical-align: middle;
}

.main form dl dt:first-child {
  margin: 0 0 5px 0;
}

.main form dl dt.required::after {
  content: "必須";
  display: inline-block;
  color: #ffffff;
  font-size: 12px;
  padding: 3px 5px;
  margin-left: 10px;
  background-color: #ff7b83;
  vertical-align: top;
  line-height: 16px;
}

.main form dl dt.magazine {
  font-weight: bold;
  margin-bottom: 20px;
}

.main form dl dt.magazine::after {
  font-weight: normal;
}

.main form dl dd input, .main form dl dd select {
  margin-top: 5px;
  border-radius: 0;
  border: 1px solid #b3b3b3;
}

.main form dl dd input::placeholder {
  opacity: 0.7;
}

.main form dl dd input.active {
  border: 1px solid #ff7b83;
}

.main form dl dd input.active2 {
  border: 1px solid #ff7b83;
}

.main form dl dd .input-inline {
  max-width: 300px;
  width: 49%;
  display: inline-block;
  margin-left: 2%;
}

.main form dl dd .input-inline:first-child {
  margin-left: 0px;
}

.main form dl dd .input-inline.adress-number {
  max-width: 100px;
  margin-left: 0;
}

.main form dl dd div.input-inline {
  vertical-align: top;
}

.main form dl dd span.input-bar {
  display: inline-block;
  padding: 0 5px;
  font-size: 20px;
}

.main form dl dd .input-inline p.input-title {
  margin-bottom: 0;
  margin-top: 0px;
}

.main form dl dd label {
  margin: 0 10px 0 0;
}

.main form dl dd label input {
  margin-right: 5px;
}

.main form dl .error-text {
  font-size: 14px;
  color: #ff7b83;
  margin: 10px 0 0 0;
  display: none;
}

.main form dl .error-text.active {
  display: block;
}

.main form dl .error-text.active2 {
  display: block;
}

.main .error-box {
  display: none;
}

.main .error-box.active-box {
  display: block !important;
  border: 1px solid #ff7b83;
  padding: 0 10px 10px 10px;
}

.main .error-box.active-box .error-text {
  font-size: 14px;
  color: #ff7b83;
  margin: 10px 0 0 0;
  display: none;
}

.main .error-box.active-box .error-text.active {
  display: block;
}

.main .error-box.active-box .error-text.active2 {
  display: block;
}

.main .btn {
  background-color: #b3b3b3;
  color: #FFFFFF;
}

.information h3 {
  color: #bfa45a;
  border: 1px solid #bfa45a;
  background-color: #F9F6EE;
}

.information p.step-sub {
  color: #bfa45a;
}

.information .flow {
  justify-content: center;
}

.information .flow li {
  border: 1px solid #bfa45a;
  color: #bfa45a;
  margin-left: 4%;
}

.information .flow li.active {
  background-color: #F9F6EE;
}

.information .flow li:first-child {
  margin-left: 0;
}

.information .flow li::after {
  background-image: url("../images/arrow02.png");
}

@media (max-width: 750px) {
  .main .card-img img {
    width: 50%;
  }
  .main h3.name-box {
    border-color: #00495b;
  }
  .main .mem-info .card-status {
    text-align: center;
    padding-top: 0;
    border-color: #00495b;
    margin-bottom: 30px;
  }
  .main .mem-info .info-list {
    display: block;
    text-align: center;
  }
  .main .mem-info .info-list dt {
    font-weight: bold;
    width: 100%;
    border-right: none;
    padding: 5px 0;
  }
  .main .mem-info .info-list dd {
    width: 100%;
    border-bottom: none;
    padding: 8px 0;
    margin-bottom: 30px;
    font-size: 18px;
  }
  .main .mem-info .info-list dd .point-link {
    float: none;
    display: block;
    background-color: transparent;
    color: #00495b;
    font-size: 14px;
    margin-top: 15px;
    font-weight: bold;
  }
  .main .mem-info .info-list dd .point-link::before {
    content: none;
  }
  .main .mem-info .info-list dd .point-link::after {
    transform: translateY(-2px);
    content: "　";
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
    width: 9px;
    height: 16px;
    background-image: url("../images/arrow04.png");
    background-size: cover;
  }
  .main .mem-info .rankup-alert {
    margin: 0 auto;
    padding: 20px;
    width: 95%;
    border: 1px solid #FF7B83;
    margin-bottom: 20px;
    position: relative;
    color: #FF7B83;
  }
  .main form dl dd .input-inline {
    margin-left: 0;
    margin-top: 10px;
    display: block;
    width: 100%;
    max-width: 100%;
  }
  .main form dl dd .input-inline:first-child {
    margin-top: 0px;
  }
  .main form dl dd .input-inline.adress-number {
    display: inline-block;
    margin-left: 0;
    margin-top: 0px;
    max-width: 20%;
  }
  .main .btn-submit {
    text-align: center;
  }
  .main .btn-submit button {
    width: 50%;
    font-size: 20px;
    font-weight: bold;
  }
  .main .btn-col2 button:first-child {
    margin: 0 0 10px 0;
  }
}

@media (max-width: 992px) {
  .main h3 {
    padding: 10px;
    font-size: 18px;
    display: block;
    background-color: transparent;
  }
  .main h3 span {
    margin-left: 10px;
  }
  .main .flow {
    margin-bottom: 20px;
  }
  .main .flow li {
    padding: 10px 15px;
  }
  .main .flow li p {
    height: auto;
    font-size: 18px;
    margin-bottom: 0;
  }
  .main .flow li::after {
    display: none;
  }
  .main .flow li.active::after {
    content: "　";
    width: 8px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    top: 124%;
    bottom: 0;
    left: auto;
    transform: translate(0, -50%) rotate(90deg);
  }
  .main .flow li:last-child::after {
    content: "　";
  }
  .main .border-box {
    padding: 15px;
  }
}
